import React from 'react';
import Frame from 'pages/order/chartrwd/Frame';
import { labels } from 'constants/labels';
import StudyYears from './StudyYears';
import Downsampling from './Downsampling';
import PatientRecord from './PatientRecord';

function GeneralStudyParameters() {
  return (
    <Frame>
      <Frame.Aside></Frame.Aside>
      <div className="d-flex flex-column gap-25 w-100">
        <div className="txt lh-24 size-16 gray-80">{labels.oneMoreStep}</div>

        <div className="d-flex flex-column">
          <StudyYears />
          <Downsampling />
          <PatientRecord />
        </div>
      </div>
    </Frame>
  );
}

export default GeneralStudyParameters;
