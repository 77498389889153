import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import atroposLogo from '../../../../assets/images/atropos_fav_logo.png';
import { Strings } from 'constants/Strings';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { ButtonCustom } from 'components/form-input/Button';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import {
  resetChartrwdErrors,
  syncModifiedPhenotypeData,
} from 'redux/modules/orderDetails/actions';
import PhenotypeUI from 'components/chartRWD/phenotype/Phenotype';
import { useOrderPath } from 'hooks/useOrderPath';
import { useParams } from 'react-router-dom';
import { labels } from 'constants/labels';

const defaultButtons = {
  TRY_AGAIN: 'Try Again',
  START_OVER: 'Start Over',
};

function Phenotype() {
  const {
    showButtonIcons,
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
    handleButtonAction,
    hasZeroOutcomeMaxValue,
    token,
  } = useOrdersPagedata();
  const dispatch = useDispatch();
  const params = useParams();
  const orderTypePath = useOrderPath();
  const { phenotypeResponse, isOpenAiLoading, PICOGuid } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const [phenotypeData, setPhenotypeData] = useState('');
  const [phenotypeTableResponseTextString, setPhenotypeTableResponseTextString] =
    useState('');
  const [picoObjectValue, setPicoObjectValue] = useState();

  const isPhenotTypeButtonDisabled = hasZeroOutcomeMaxValue || false;

  useEffect(() => {
    if (Object.keys(phenotypeData).length === 0) {
      setInitialPhenotypeData();
    }
  }, [phenotypeResponse]);

  useEffect(() => {
    var response = phenotypeData;
    var picoPObj = {};
    var isMatching = false;
    var isError = false;
    var count = 0;
    if (response !== undefined && response !== '' && response !== null) {
      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          if (response[key] && Object.keys(response[key]).length !== 0) {
            picoPObj[key] = {};
            isError = false;
            isMatching = false;
            count = 0;
            if (response[key]?.entities?.length === 0) {
              picoPObj[key] = { isError: true, isMatching: isMatching, count: count };
            } else {
              for (let i = 0; i < response[key]?.entities?.length; i++) {
                if (
                  Object.prototype.toString
                    .call(response[key]?.entities[i]?.phenotypes)
                    .indexOf('Array') > -1
                ) {
                  count += response[key]?.entities[i]?.phenotypes[0].code_set.length;
                  var phenotypeRankedObj = response[key]?.entities[i]?.phenotypes[0];
                  if (
                    phenotypeRankedObj?.code_set !== undefined &&
                    phenotypeRankedObj?.code_set.length !== 0
                  ) {
                    phenotypeRankedObj?.code_set.map((code, index) => {
                      isMatching = true;
                      if (Strings.phenotypeError === code.concept_description) {
                        isError = true;
                      } else {
                        isError = false;
                      }
                    });
                  } else {
                    isError = true;
                  }
                }
              }
              picoPObj[key] = { isError: isError, isMatching: isMatching, count: count };
            }
          }
        }
      }
    }
    showPhenotypetext(picoPObj);
  }, [phenotypeResponse, phenotypeData]);

  useEffect(() => {
    var response = phenotypeData;
    // setPhenotypesResponseObject(response);
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'userPhenotypes',
        value: response,
      },
    });
  }, [phenotypeData, picoObjectValue]);

  function checkKeyValues(picoObj, keyToCheck, valueToCheck) {
    if (Object.keys(picoObj).length !== 0) {
      for (const key in picoObj) {
        if (picoObj[key][keyToCheck] !== valueToCheck) {
          return false;
        }
      }
      return true;
    }
  }

  const showPhenotypetext = (picoObject) => {
    var isError = false;
    var text = '';
    setPicoObjectValue(picoObject);
    if (Object.keys(picoObject).length !== 0) {
      var isOnePicoPresent = checkKeyValues(picoObject, 'isMatching', true);
      for (const key in picoObject) {
        if (picoObject[key].isError) {
          isError = true;
        }
      }
      if (isOnePicoPresent && isError) {
        text = Strings.phenotypeTableResponseText1;
      } else if (isError) {
        text = Strings.phenotypeTableResponseText2;
      } else {
        text = Strings.phenotypeTableResponseText3;
      }

      setPhenotypeTableResponseTextString(text);
    }
  };

  const setInitialPhenotypeData = () => {
    if (phenotypeResponse?.phenotypes && phenotypeResponse !== undefined)
      setPhenotypeData(JSON.parse(JSON.stringify(phenotypeResponse?.phenotypes)));
  };

  function movePhenotypesAccurateToEnd(buttons) {
    if (!buttons) return buttons;

    if (buttons?.PHENOTYPES_ACCURATE) {
      let accurateValue = buttons.PHENOTYPES_ACCURATE;
      delete buttons.PHENOTYPES_ACCURATE;
      buttons.PHENOTYPES_ACCURATE = accurateValue;
    }
    return buttons;
  }

  const showPhenotypeResponseButtons = () => {
    var buttons = [];
    var button = '';

    var phenotypeButtons = phenotypeResponse?.buttons
      ? phenotypeResponse?.buttons
      : phenotypeButtons
      ? phenotypeButtons
      : defaultButtons;
    const buttonsObj = movePhenotypesAccurateToEnd(phenotypeButtons);

    if (buttonsObj) {
      for (const key in buttonsObj) {
        button = (
          <React.Fragment key={key}>
            {/* {key === 'PHENOTYPES_ACCURATE' && <div className="flex-basis-100"> </div>} */}
            <ButtonCustom
              key={key}
              cssClass={`${
                chatRWD.isPhenotypeFinalized && !hasZeroOutcomeMaxValue ? 'active-bg' : ''
              } ${
                key === aiButtonsEnum.PHENOTYPES_ACCURATE &&
                (Strings.phenotypeTableResponseText2 ===
                  phenotypeTableResponseTextString ||
                  isPhenotTypeButtonDisabled)
                  ? 'btn-disabled'
                  : ''
              }  ${
                key === aiButtonsEnum.MANUALLY_EDIT_PICO &&
                chatRWD.isPhenotypeFinalized &&
                'd-none'
              } 
              
              blue-btn mb-3 mx-md-1 mx-0`}
              buttonAction={`${buttonsObj[key]} button clicked`}
              id={`bt-${buttonsObj[key]}-button`}
              onClick={() => onClickOfbutton(key)}
              disabled={
                key === aiButtonsEnum.PHENOTYPES_ACCURATE &&
                (Strings.phenotypeTableResponseText2 ===
                  phenotypeTableResponseTextString ||
                  isPhenotTypeButtonDisabled)
              }
            >
              <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                {showButtonIcons(key)}
                <span className="text-capitalize">{buttonsObj[key]}</span>
              </div>
            </ButtonCustom>
          </React.Fragment>
        );
        buttons.push(button);
      }
    }

    return buttons;
  };

  const onClickOfbutton = (buttonAction) => {
    dispatch(resetChartrwdErrors());
    switch (buttonAction) {
      case aiButtonsEnum.PHENOTYPES_ACCURATE:
        dispatchOrderPageAction({
          type: 'order/setLoadingTitle',
          payload: Strings.pleaseWait,
        });

        if (!chatRWD?.isPhenotypeFinalized) {
          let uniqueModifiedParams;
          if (
            phenotypeResponse?.modified_params &&
            phenotypeResponse?.modified_params.length
          ) {
            uniqueModifiedParams = [...new Set(phenotypeResponse.modified_params)];
          }
          const payload = {
            ai_search_history_guid: PICOGuid,
            user_phenotypes: phenotypeResponse?.phenotypes,
            modified_params: uniqueModifiedParams,
            previous_order_guid: params?.guid,
            is_reorder: orderTypePath === 'reorder' ? true : false,
          };
          dispatch(
            syncModifiedPhenotypeData({
              token,
              params: payload,
            }),
          );
        }

        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'isPhenotypeFinalized',
            value: true,
          },
        });
        break;
      default:
        handleButtonAction({ key: buttonAction });
    }
  };

  return (
    <div className="ai-worflow-container" data-testid="phenotype-section">
      <div className="d-md-flex green-bg px-md-6 px-3 py-md-4 py-3">
        <div className="order-logo-container w-10">
          <img src={atroposLogo} alt="logo" />
        </div>

        {phenotypeResponse?.message && (
          <div className="w-80">{phenotypeResponse?.message}</div>
        )}
        {(phenotypeResponse &&
          Object.keys(phenotypeData).length !== 0 &&
          !isOpenAiLoading) ||
        (phenotypeData !== '' && !isOpenAiLoading) ? (
          <div className={`w-90`}>
            <div className="mb-4">
              The following table is our AI generated list of phenotypes:
            </div>

            <PhenotypeUI data={phenotypeResponse?.phenotypes} />
            <div className="mt-4">{phenotypeTableResponseTextString}</div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className={`flex-wrap flex-justify-end green-border pt-3 px-md-2 px-2 ${
          !isOpenAiLoading && (phenotypeData !== undefined || phenotypeData !== '')
            ? 'd-md-flex'
            : 'd-none'
        }`}
      >
        {showPhenotypeResponseButtons()}
      </div>
    </div>
  );
}

export default Phenotype;
