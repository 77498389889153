export const SET_ERROR = 'orderDetails/setError';
export const GET_QUESTION_TYPES = 'orderDetails/getQuestionTypes';
export const GET_QUESTION_TYPES_START = 'orderDetails/getQuestionTypesStart';
export const GET_QUESTION_TYPES_COMPLETE = 'orderDetails/getQuestionTypesCompleted';
export const GET_QUESTION_TYPES_ERROR = 'ratorderDetailsing/getQuestionTypesError';

export const GET_MOTIVATIONS = 'orderDetails/getMotivations';
export const GET_MOTIVATIONS_START = 'orderDetails/getMotivationsStart';
export const GET_MOTIVATIONS_COMPLETE = 'orderDetails/getMotivationsCompleted';
export const GET_MOTIVATIONS_ERROR = 'ratorderDetailsing/getMotivationsError';

export const ORDER_PROGNOSTOGRAM = 'orderDetails/orderPrognostogram';
export const ORDER_PROGNOSTOGRAM_START = 'orderDetails/orderPrognostogramStart';
export const ORDER_PROGNOSTOGRAM_COMPLETE = 'orderDetails/orderPrognostogramCompleted';
export const ORDER_PROGNOSTOGRAM_ERROR = 'orderDetails/orderPrognostogramError';

export const SET_LOADING = 'orderDetails/setLoading';
export const RESET_DATA = 'orderDetails/resetData';
export const SET_ORDER_FORM_DATA = 'orderDetails/setOrderFormData';
export const CLEAR_ORDER_FORM_DATA = 'orderDetails/clearOrderFormData';
export const RESET_LOCAL_DATA = 'orderDetails/resetLocalData';
export const SET_SEARCH_QUERY_TEXT = 'orderDetails/setSearchQueryText';

export const GET_ORDER_DETAILS = 'orderDetails/getOrderDetails';
export const GET_ORDER_DETAILS_START = 'orderDetails/getOrderDetailsStart';
export const GET_ORDER_DETAILS_COMPLETE = 'orderDetails/getOrderDetailsCompleted';
export const GET_ORDER_DETAILS_ERROR = 'ratorderDetailsing/getOrderDetailsError';

export const GET_SEARCH_QUERY_DETAILS = 'orderDetails/getSearchQueryDetails';
export const GET_SEARCH_QUERY_DETAILS_START = 'orderDetails/getSearchQueryDetailsStart';
export const GET_SEARCH_QUERY_DETAILS_COMPLETE =
  'orderDetails/getSearchQueryDetailsCompleted';
export const GET_SEARCH_QUERY_DETAILS_ERROR =
  'ratorderDetailsing/getSearchQueryDetailsError';

export const GET_SEARCH_DATA = 'orderDetails/getSearchData';
export const GET_SEARCH_DATA_START = 'orderDetails/getSearchDataStart';
export const GET_SEARCH_DATA_COMPLETE = 'orderDetails/getSearchDataCompleted';
export const GET_SEARCH_DATA_ERROR = 'ratorderDetailsing/getSearchDataError';

export const GET_ORDER_STATUS_COUNT = 'orderDetails/getOrderStatusCount';
export const GET_ORDER_STATUS_COUNT_START = 'orderDetails/getOrderStatusCountStart';
export const GET_ORDER_STATUS_COUNT_COMPLETE =
  'orderDetails/getOrderStatusCountCompleted';
export const GET_ORDER_STATUS_COUNT_ERROR = 'ratorderDetailsing/getOrderStatusCountError';

export const GET_PROGNOSTOGRAM_PDF = 'orderDetails/getPrognostogramPdf';
export const GET_PROGNOSTOGRAM_PDF_START = 'orderDetails/getPrognostogramPdfStart';
export const GET_PROGNOSTOGRAM_PDF_COMPLETE = 'orderDetails/getPrognostogramPdfCompleted';
export const GET_PROGNOSTOGRAM_PDF_ERROR = 'orderDetails/getPrognostogramPdfError';
export const RESET_PROGNOSTOGRAM_PDF = 'orderDetails/resetPrognostogramPdf';

export const DELETE_ORDER = 'orderDetails/deleteOrder';
export const DELETE_ORDER_START = 'orderDetails/deleteOrderStart';
export const DELETE_ORDER_COMPLETE = 'orderDetails/deleteOrderCompleted';
export const DELETE_ORDER_ERROR = 'orderDetails/deleteOrderError';

export const MODIFY_PROGNOSTOGRAM_ORDER = 'orderDetails/modifyPrognostogramOrder';
export const MODIFY_PROGNOSTOGRAM_ORDER_START =
  'orderDetails/modifyPrognostogramOrderStart';
export const MODIFY_PROGNOSTOGRAM_ORDER_COMPLETE =
  'orderDetails/modifyPrognostogramOrderCompleted';
export const MODIFY_PROGNOSTOGRAM_ORDER_ERROR =
  'orderDetails/modifyPrognostogramOrderError';

export const SET_ORDER_SCOPE_DATA = 'orderDetails/setOrderScopeData';

export const GET_SPECIALTY_LIST = 'orderDetails/getSpecialtyList';
export const GET_SPECIALTY_LIST_START = 'orderDetails/getSpecialtyListStart';
export const GET_SPECIALTY_LIST_COMPLETE = 'orderDetails/getSpecialtyListCompleted';
export const GET_SPECIALTY_LIST_ERROR = 'orderDetails/getSpecialtyListError';

export const GET_ORDER_BY_GUID = 'orderDetails/getorderByGuid';
export const GET_ORDER_BY_GUID_START = 'orderDetails/getorderByGuidStart';
export const GET_ORDER_BY_GUID_COMPLETE = 'orderDetails/getorderByGuidCompleted';
export const GET_ORDER_BY_GUID_ERROR = 'orderDetails/getorderByGuidError';
export const RESET_SINGLE_ORDER_DATA = 'orderDetails/resetSingleOrderData';

export const UPLOAD_ORDER_DOCUMENT = 'orderDetails/uploadOrderDocument';
export const UPLOAD_ORDER_DOCUMENT_START = 'orderDetails/uploadOrderDocumentStart';
export const UPLOAD_ORDER_DOCUMENT_COMPLETE = 'orderDetails/uploadOrderDocumentCompleted';
export const UPLOAD_ORDER_DOCUMENT_ERROR = 'orderDetails/uploadOrderDocumentError';
export const RESET_UPLOADED_DOCUMENT_DATA = 'orderDetails/resetUploadDocumentData';

export const GET_DOWNLOAD_LINK = 'orderDetails/getDownloadLink';
export const GET_DOWNLOAD_LINK_START = 'orderDetails/getDownloadLinkStart';
export const GET_DOWNLOAD_LINK_COMPLETE = 'orderDetails/getDownloadLinkCompleted';
export const GET_DOWNLOAD_LINK_ERROR = 'orderDetails/geDownloadLinkError';
export const RESET_DOWNLOAD_LINK = 'orderDetails/resetDownloadLink';

export const FEATCH_PICO_TO_PHENOTYPE = 'orderDetails/fetchPicoToPhenotype';
export const FEATCH_PICO_TO_PHENOTYPE_START = 'orderDetails/fetchPicoToPhenotypeStart';
export const FEATCH_PICO_TO_PHENOTYPE_COMPLETE =
  'orderDetails/fetchPicoToPhenotypeCompleted';
export const FEATCH_PICO_TO_PHENOTYPE_ERROR = 'orderDetails/fetchPicoToPhenotypeError';
export const SET_PHENOTYPE_DATA = 'orderDetails/setPhenotypeData';
export const RESET_PHENOTYPE_DATA = 'orderDetails/resetPhenotypeData';

export const SAVE_PICO_OPTIONS = 'orderDetails/savePicoOptions';
export const SAVE_PICO_OPTIONS_START = 'orderDetails/savePicoOptionsStart';
export const SAVE_PICO_OPTIONS_COMPLETE = 'orderDetails/savePicoOptionsCompleted';
export const SAVE_PICO_OPTIONS_ERROR = 'orderDetails/savePicoOptionsError';
export const RESET_PICO_OPTIONS_DATA = 'orderDetails/resetPicoOptionsData';
export const RESET_PICO_DATA = 'orderDetails/resetPicoData';
export const RESET_CHATRWD_ERRORS = 'orderDetails/resetChartrwdErrors';

export const RESET_ORDER_INDEX_DATA = 'orderDetails/resetOrderIndexData';
export const SET_ORDER_INDEX_DATA = 'orderDetails/setOrderIndexData';
export const SET_SPECIALTY_TEXT = 'orderDetails/setSpecialtyText';
export const SET_CHATRWD_LOADER_CLOSE_CLICKED =
  'orderDetails/setChatrwdLoaderCloseClicked';

export const FEATCH_ORDER_TASK_STATUS = 'orderDetails/fetchOrderTaskStatus';
export const FEATCH_ORDER_TASK_STATUS_START = 'orderDetails/fetchOrderTaskStatusStart';
export const FEATCH_ORDER_TASK_STATUS_COMPLETE =
  'orderDetails/fetchOrderTaskStatusCompleted';
export const FEATCH_ORDER_TASK_STATUS_ERROR = 'orderDetails/fetchOrderTaskStatusError';
export const SET_SEARCH_ORDER_LOADING = 'orderDetails/setSearchOrderLoading';
export const SET_UPLOADED_FILE = 'orderDetails/setUploadedFile';
export const REMOVE_UPLOADED_FILE = 'orderDetails/removeUploadedFile';
export const CLEAR_UPLOADED_DOCUMENTS_LIST = 'orderDetails/clearUploadDocumentsList';

export const FEATCH_SUGGESTIONS_THROUGH_OPEN_AI =
  'orderDetails/fetchSuggestionsThroughOpenAi';
export const FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_START =
  'orderDetails/fetchSuggestionsThroughOpenAiStart';
export const FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE =
  'orderDetails/fetchSuggestionsThroughOpenAiCompleted';
export const FEATCH_SUGGESTIONS_THROUGH_OPEN_AI_ERROR =
  'orderDetails/fetchSuggestionsThroughOpenAiError';
export const SET_SUGGESTIONS_WITH_INDEX = 'orderDetails/setSuggestionsWithIndex';
export const REMOVE_STUDY_FORMAT_WITH_INDEX = 'orderDetails/removeStudyFormatWithIndex';
export const RESET_SUGGESTIONS = 'orderDetails/resetSuggestions';
export const CLEAR_STUDY_FORMATS = 'orderDetails/clearStudyFormats';
export const SET_PICO_GUID = 'orderDetails/setPICOGuid';
export const INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI =
  'orderDetails/incorporateSuggestionsThroughOpenAi';
export const INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_START =
  'orderDetails/incorporateSuggestionsThroughOpenAiStart';
export const INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_COMPLETE =
  'orderDetails/incorporateSuggestionsThroughOpenAiCompleted';
export const INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI_ERROR =
  'orderDetails/incorporateSuggestionsThroughOpenAiError';
export const HANDLE_ORDERDETAIL_MODAL = 'orderDetails/handleOrderDetailModal';

export const GET_ORDER_NOTIFICATION_DETAILS = 'orderDetails/getOrderNotificationDetails';
export const GET_ORDER_NOTIFICATION_DETAILS_START =
  'orderDetails/getOrderNotificationDetailsStart';
export const GET_ORDER_NOTIFICATION_DETAILS_COMPLETE =
  'orderDetails/getOrderNotificationDetailsComplete';
export const GET_ORDER_NOTIFICATION_DETAILS_ERROR =
  'orderDetails/getOrderNotificationDetailsError';
export const RESET_ORDER_NOTIFICATION_DETAILS =
  'orderDetails/resetOrderNotificationDetails';
export const HANDLE_CANCEL_ORDER_MODAL = 'orderDetails/handleCancelOrderModal';
export const SET_CURRENT_ORDER_STATUS = 'SET_CURRENT_ORDER_STATUS';
export const SET_MOTIVATION = 'SET_MOTIVATION';
export const UPDATE_PICO_SUGGESTIONS = 'orderDetails/updatePicoSuggestions';
export const RESET_STUDY_FORMATS = 'orderDetails/resetStudyFormats';
export const UPDATE_STUDY_DETAIL_KEY_WITH_INDEX =
  'orderDetails/updateStudyDetailKeyWithIndex';
export const UPDATE_STUDY_DETAIL_DATA = 'orderDetails/updateStudyDetails';

export const FEATCH_RESULT_THROUGH_OPEN_AI = 'orderDetails/fetchResultThroughOpenAi';
export const FEATCH_RESULT_THROUGH_OPEN_AI_START =
  'orderDetails/fetchResultThroughOpenAiStart';
export const FEATCH_RESULT_THROUGH_OPEN_AI_COMPLETE =
  'orderDetails/fetchResultThroughOpenAiCompleted';
export const FEATCH_RESULT_THROUGH_OPEN_AI_ERROR =
  'orderDetails/fetchResultThroughOpenAiError';

export const SYNC_MODIFIED_PHENOTYPE_DATA = 'orderDetails/syncModifiedPhenotypeData ';
export const SYNC_MODIFIED_PHENOTYPE_DATA_START =
  'orderDetails/syncModifiedPhenotypeDataStart';
export const SYNC_MODIFIED_PHENOTYPE_DATA_COMPLETE =
  'orderDetails/syncModifiedPhenotypeDataComplete';
export const SYNC_MODIFIED_PHENOTYPE_DATA_ERROR =
  'orderDetails/syncModifiedPhenotypeDataError';

export const fetchOrderTaskStatus = (payload = {}) => ({
  type: FEATCH_ORDER_TASK_STATUS,
  payload,
});

export const setChatrwdLoaderCloseClicked = (payload = {}) => ({
  type: SET_CHATRWD_LOADER_CLOSE_CLICKED,
  payload,
});

export const resetChartrwdErrors = (payload = {}) => ({
  type: RESET_CHATRWD_ERRORS,
  payload,
});

export const resetPicoData = (payload = {}) => ({
  type: RESET_PICO_DATA,
  payload,
});

export const resetPicoOptionsData = (payload = {}) => ({
  type: RESET_PICO_OPTIONS_DATA,
  payload,
});

export const savePicoOptions = (payload = {}) => ({
  type: SAVE_PICO_OPTIONS,
  payload,
});

export const fetchPicoToPhenotype = (payload = {}) => ({
  type: FEATCH_PICO_TO_PHENOTYPE,
  payload,
});

export const resetPhenotypeData = (payload = {}) => ({
  type: RESET_PHENOTYPE_DATA,
  payload,
});

export const fetchResultThroughOpenAi = (payload = {}) => ({
  type: FEATCH_RESULT_THROUGH_OPEN_AI,
  payload,
});

export const resetPrognostogramPdf = (payload = {}) => ({
  type: RESET_PROGNOSTOGRAM_PDF,
  payload,
});

export const resetDownloadLink = (payload = {}) => ({
  type: RESET_DOWNLOAD_LINK,
  payload,
});

export const getDownloadLink = (payload = {}) => ({
  type: GET_DOWNLOAD_LINK,
  payload,
});

export const uploadOrderDocument = (payload = {}) => ({
  type: UPLOAD_ORDER_DOCUMENT,
  payload,
});

export const resetUploadDocumentData = (payload = {}) => ({
  type: RESET_UPLOADED_DOCUMENT_DATA,
  payload,
});

export const getorderByGuid = (payload = {}) => ({
  type: GET_ORDER_BY_GUID,
  payload,
});

export const resetSingleOrderData = (payload = {}) => ({
  type: RESET_SINGLE_ORDER_DATA,
  payload,
});

export const getSpecialtyList = (payload = {}) => ({
  type: GET_SPECIALTY_LIST,
  payload,
});

export const deleteOrder = (payload = {}) => ({
  type: DELETE_ORDER,
  payload,
});

export const modifyPrognostogramOrder = (payload = {}) => ({
  type: MODIFY_PROGNOSTOGRAM_ORDER,
  payload,
});

export const getPrognostogramPdf = (payload = {}) => ({
  type: GET_PROGNOSTOGRAM_PDF,
  payload,
});

export const getOrderStatusCount = (payload = {}) => ({
  type: GET_ORDER_STATUS_COUNT,
  payload,
});

export const getSearchData = (payload = {}) => ({
  type: GET_SEARCH_DATA,
  payload,
});

export const getSearchQueryDetails = (payload = {}) => ({
  type: GET_SEARCH_QUERY_DETAILS,
  payload,
});

export const getOrderDetails = (payload = {}) => ({
  type: GET_ORDER_DETAILS,
  payload,
});

export const setError = (payload = {}) => ({
  type: SET_ERROR,
  payload,
});

export const getQuestionTypes = (payload = {}) => ({
  type: GET_QUESTION_TYPES,
  payload,
});

export const getMotivations = (payload = {}) => ({
  type: GET_MOTIVATIONS,
  payload,
});

export const orderPrognostogram = (payload = {}) => ({
  type: ORDER_PROGNOSTOGRAM,
  payload,
});

export const setLoading = (payload = []) => ({
  type: SET_LOADING,
  payload,
});

export const resetData = (payload = []) => ({
  type: RESET_DATA,
  payload,
});

export const resetLocalData = (payload = []) => {
  localStorage.removeItem('persist:root');
  return {
    type: RESET_LOCAL_DATA,
    payload,
  };
};

export const setOrderFormData = (payload = {}) => ({
  type: SET_ORDER_FORM_DATA,
  payload,
});

export const setOrderScopeData = (payload = {}) => ({
  type: SET_ORDER_SCOPE_DATA,
  payload,
});

export const setOrderIndexData = (payload = {}) => ({
  type: SET_ORDER_INDEX_DATA,
  payload,
});

export const resetOrderIndexData = (payload = {}) => ({
  type: RESET_ORDER_INDEX_DATA,
  payload,
});

export const setSearchQueryText = (payload = {}) => ({
  type: SET_SEARCH_QUERY_TEXT,
  payload,
});

export const setSpecialtyText = (payload = {}) => ({
  type: SET_SPECIALTY_TEXT,
  payload,
});

export const clearOrderFormData = (payload = {}) => ({
  type: CLEAR_ORDER_FORM_DATA,
  payload,
});

export const getorderByGuidError = (payload = {}) => ({
  type: GET_ORDER_BY_GUID_ERROR,
  payload,
});

export const setSearchOrderLoading = (payload) => ({
  type: SET_SEARCH_ORDER_LOADING,
  payload,
});

export const setUploadedFile = (payload) => ({
  type: SET_UPLOADED_FILE,
  payload,
});

export const removeUploadedFile = (payload) => ({
  type: REMOVE_UPLOADED_FILE,
  payload,
});

export const clearUploadDocumentsList = (payload) => ({
  type: CLEAR_UPLOADED_DOCUMENTS_LIST,
  payload,
});

export const fetchSuggestionsThroughOpenAi = (payload = {}) => ({
  type: FEATCH_SUGGESTIONS_THROUGH_OPEN_AI,
  payload,
});

export const resetSuggestions = (payload = {}) => ({
  type: RESET_SUGGESTIONS,
  payload,
});

export const setPICOGuid = (payload) => ({
  type: SET_PICO_GUID,
  payload,
});

export const incorporateSuggestionsThroughOpenAi = (payload = {}) => ({
  type: INCORPORATE_SUGGESTIONS_THROUGH_OPEN_AI,
  payload,
});

export const setSuggestionsWithIndex = (payload = {}) => ({
  type: SET_SUGGESTIONS_WITH_INDEX,
  payload,
});

export const removeStudyFormatWithIndex = (payload = {}) => ({
  type: REMOVE_STUDY_FORMAT_WITH_INDEX,
  payload,
});

export const handleOrderDetailModal = (payload) => ({
  type: HANDLE_ORDERDETAIL_MODAL,
  payload,
});

export const getOrderNotificationDetails = (payload) => ({
  type: GET_ORDER_NOTIFICATION_DETAILS,
  payload,
});

export const resetOrderNotificationDetails = (payload) => ({
  type: RESET_ORDER_NOTIFICATION_DETAILS,
  payload,
});

export const handleCancelOrderModal = (payload) => ({
  type: HANDLE_CANCEL_ORDER_MODAL,
  payload,
});

export const setCurrentOrderStatus = (payload) => ({
  type: SET_CURRENT_ORDER_STATUS,
  payload: payload,
});

export const setMotivation = (payload) => ({
  type: SET_MOTIVATION,
  payload: payload,
});

export const updatePicoSuggestions = (payload) => ({
  type: UPDATE_PICO_SUGGESTIONS,
  payload: payload,
});

export const resetStudyFormats = (payload) => ({
  type: RESET_STUDY_FORMATS,
  payload: payload,
});

export const clearStudyFormats = (payload = {}) => ({
  type: CLEAR_STUDY_FORMATS,
  payload,
});

export const updateStudyDetailKeyWithIndex = (payload = {}) => ({
  type: UPDATE_STUDY_DETAIL_KEY_WITH_INDEX,
  payload,
});

export const updateStudyDetails = (payload) => ({
  type: UPDATE_STUDY_DETAIL_DATA,
  payload,
});

export const setPhenotypeData = (payload) => ({
  type: SET_PHENOTYPE_DATA,
  payload,
});

export const syncModifiedPhenotypeData = (payload) => ({
  type: SYNC_MODIFIED_PHENOTYPE_DATA,
  payload,
});
