import React from 'react';
import StyledButton from './StyledButton';
import { useMixpanel } from 'react-mixpanel-browser';

function Button({
  label,
  icon,
  type,
  outlined,
  onClick,
  disabled,
  actionLabel,
  id,
  severity,
}) {
  const mixpanel = useMixpanel();

  function handleOnClick() {
    const buttonAction = label + ' ' + 'button clicked';
    mixpanel.track(buttonAction, {
      action_value: label,
      action: actionLabel,
    });
    onClick();
  }

  return (
    <StyledButton
      className="ah-button"
      outlined={outlined}
      label={label}
      icon={icon}
      type={type}
      onClick={handleOnClick}
      disabled={disabled}
      severity={severity}
      id={id}
    />
  );
}

export default Button;
