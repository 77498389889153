import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components';

const StyledInputTextarea = styled(InputTextarea)`
  &.ah-texarea {
    width: 100%;
    font-size: ${(props) => props.fontSize || '16px'};
    border: ${(props) => props.border || '1px solid var(--border-gray-300)'};
    border-radius: ${(props) => props.borderRadius || 'var(--border-radius-md)'};
    color: ${(props) => props.fontColor || 'var(--color-black-opacity-87)'};
    padding: ${(props) => props.padding || '16px'};
    background: ${(props) => props.background || 'var(--bg-gray-50)'};

    &::placeholder {
      color: ${(props) => props.placeholderColor || 'var(--color-Neutral-400)'};
      font-size: ${(props) => props.placeholderfontSize || 'var(--color-Neutral-400)'};
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    :hover {
      border-color: var(--border-secondary-300);
    }

    :enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 2px var(--border-secondary-300);
      border-color: var(--border-secondary-300);
    }

    @media (min-width: 1200px) {
      padding: 30px;
    }
  }
`;

export default StyledInputTextarea;
