import React from 'react';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { InputTextarea } from 'components/form/InputTextarea';
import { ReactComponent as EditIcon } from 'assets/images/edit.svg';
import { ReactComponent as MagicpenIcon } from 'assets/images/magicpen.svg';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { labels } from 'constants/labels';
import { Button } from 'components/button';

function ClinicalQuestionForm() {
  const {
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
    handleButtonAction,
  } = useOrdersPagedata();
  const { questionTitle } = chatRWD;

  function handleChange(e) {
    const { value } = e.target;
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'questionTitle',
        value: value,
      },
    });
    dispatchOrderPageAction({
      type: 'order/updatePICOOrderDetailsField',
      payload: {
        field: 'clinicalQuestion',
        value: value,
      },
    });
  }

  function handleSubmit(action) {
    const option = { key: action };
    handleButtonAction(option);
  }

  return (
    <form>
      <div className="d-flex flex-column gap-25">
        <InputTextarea
          placeholder={labels.inputQuestionPlaceholder}
          value={questionTitle}
          onChange={handleChange}
          id="textarea-clinical-question"
        />
        <div className="d-flex flex-wrap flex-md-row-reverse gap-10">
          <Button
            outlined
            label="Generate Study"
            icon={<MagicpenIcon />}
            type="button"
            onClick={() => handleSubmit(aiButtonsEnum.GENERATE_PICO)}
            disabled={!questionTitle}
            id="generate-study-btn"
            actionLabel="Study Generated"
          />
          <Button
            outlined
            label="Manually Enter Study Parameters"
            icon={<EditIcon />}
            type="button"
            onClick={() => handleSubmit(aiButtonsEnum.MANUALLY_ENTER_PICO)}
            id="manually-enter-study-parameters-btn"
            actionLabel="Manually Enter Study Parameters button clicked"
          />
        </div>
      </div>
    </form>
  );
}

export default ClinicalQuestionForm;
