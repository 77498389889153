import React from 'react';
import { ToggleablePanel } from 'components/Panel';
import { labels } from 'constants/labels';
import styles from './studyparameters.module.scss';
import { InputText } from 'components/form/input';
import DropdownMenu from 'components/dropdowns/DropdownMenu';
import { InputNumber } from 'components/form/inputNumber';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { Strings } from 'constants/Strings';

const outcomePeriodOptions = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Years',
    value: 'years',
  },
];

function PatientRecord() {
  const {
    ordersPageData: { chatRWD },
    dispatchOrderPageAction,
  } = useOrdersPagedata();
  const { generalStudyParameters } = chatRWD;

  function handleInputChange({ e, name }) {
    const payload = {
      [name]: e.value,
    };

    dispatchOrderPageAction({
      type: 'order/updateGeneralStudyParametersValues',
      payload,
    });
  }

  function handleSelect(type, option) {
    const payload = {
      [type]: option?.value,
    };
    dispatchOrderPageAction({
      type: 'order/updateGeneralStudyParametersValues',
      payload,
    });
  }

  return (
    <ToggleablePanel
      panelClassName="none-padding-bottom"
      collapsed={false}
      header={labels.minimumLengthofPatientRecord}
    >
      <div className="study-param-inputs">
        <div
          className={`d-flex align-items-center w-100 gap-25 ${styles.patientCarePanelContainer}`}
        >
          <div className="d-xl-flex flex-column flex-md-row gap-50 w-100">
            <div className={`d-flex flex-column gap-10 ${styles.prePostIndexContainer}`}>
              <div className="txt size-14 dark lh-20">{labels.preIndex}</div>
              <div className="d-flex flex-column flex-lg-row gap-10">
                <InputNumber
                  height="45px"
                  id="min_followup_type_inputs"
                  value={generalStudyParameters?.min_history_value}
                  name="min_history_type"
                  onChange={(e) => handleInputChange({ e, name: 'min_history_value' })}
                  min={0}
                />
                <DropdownMenu
                  options={outcomePeriodOptions}
                  selectedOptions={outcomePeriodOptions.find(
                    (op) => op.value === generalStudyParameters?.min_history_type,
                  )}
                  onClickonOption={(option) => handleSelect('min_history_type', option)}
                  placeholder="Select"
                  height="45px"
                />
              </div>
            </div>
            <div className={`d-flex flex-column gap-10 ${styles.prePostIndexContainer}`}>
              <div className="txt size-14 dark lh-20">{labels.postIndex}</div>
              <div className="d-flex flex-column flex-lg-row gap-10">
                <InputNumber
                  height="45px"
                  id="min_history_type_inputs"
                  value={generalStudyParameters?.min_followup_value}
                  name="min_followup_type"
                  onChange={(e) => handleInputChange({ e, name: 'min_followup_value' })}
                  min={0}
                />
                <DropdownMenu
                  options={outcomePeriodOptions}
                  selectedOptions={outcomePeriodOptions.find(
                    (op) => op.value === generalStudyParameters?.min_followup_type,
                  )}
                  onClickonOption={(option) => handleSelect('min_followup_type', option)}
                  name="min_followup_type"
                  placeholder="Select"
                  height="45px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {(generalStudyParameters?.min_history_value == null ||
        generalStudyParameters?.min_followup_value == null) && (
        <div className="color-error error-text">{Strings.PrePostIndexError}</div>
      )}
    </ToggleablePanel>
  );
}

export default PatientRecord;
